import React from "react";
import { useSearchParams } from "react-router-dom";
import axios from "../utils/axios";

import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import ErrorOutlineTwoToneIcon from "@mui/icons-material/ErrorOutlineTwoTone";

function ValidateEmail() {
  const [searchParams] = useSearchParams();

  const [verifyValues, setVerifyValues] = React.useState({
    tokenSent: false,
    verificationSuccess: false,
    errorMessage: "",
    email: "",
  });

  React.useEffect(() => {
    let token = searchParams.get("token");
    if (token === null) {
      token = "";
    }
    const getErrorMessage = (error: any) => {
      const message_default =
        error.message || "Something went wrong, try again later";
      const error_data = error.response.data;
      const message = error_data.detail ? error_data.detail : message_default;
      return message;
    };
    const validateEmail = (token: string) => {
      axios
        .post("/users/verify_email", {
          token,
        })
        .then((res) => {
          const email = res.data.email;
          setVerifyValues({
            ...verifyValues,
            tokenSent: true,
            verificationSuccess: true,
            email: email,
          });
        })
        .catch((err) => {
          setVerifyValues({
            ...verifyValues,
            tokenSent: true,
            errorMessage: getErrorMessage(err),
          });
        });
    };
    validateEmail(token);
  }, []);

  return (
    <main>
      {verifyValues.verificationSuccess && (
        <div>
          <h2>Your emil is verified!</h2>
          <h3>
            <CheckCircleTwoToneIcon color="success" fontSize="large" />
            {verifyValues.email}
          </h3>
        </div>
      )}

      {verifyValues.errorMessage && (
        <div>
          <h2>Error trying to verify your email</h2>
          <h3>
            <ErrorOutlineTwoToneIcon color="error" fontSize="large" />
            {verifyValues.errorMessage}
          </h3>
        </div>
      )}
    </main>
  );
}

export default ValidateEmail;
