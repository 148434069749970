import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import Stack from "@mui/material/Stack";
import axios from "../../utils/axios";
import { Formik } from "formik";
import YellowButton from "../buttons/YellowButton";
import {
  Box,
  IconButton,
  InputAdornment,
  FormHelperText,
  Alert,
  TextField,
} from "@mui/material";

import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import VisibilityOffTwoToneIcon from "@mui/icons-material/VisibilityOffTwoTone";

const resetPassword = async (newPassword: string, token: string) => {
  await axios.post("/users/reset_password", {
    new_password: newPassword,
    token,
  });
};

function ResetPasswordForm() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [resetValues, setResetValues] = React.useState({
    showNewPassword: false,
    showConfirmNewPassword: false,
    expiredLink: false,
  });

  const handleClickShowNewPassword = () => {
    setResetValues({
      ...resetValues,
      showNewPassword: !resetValues.showNewPassword,
    });
  };

  const handleClickShowConfirmNewPassword = () => {
    setResetValues({
      ...resetValues,
      showConfirmNewPassword: !resetValues.showConfirmNewPassword,
    });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <Formik
      initialValues={{
        newPassword: "",
        confirmNewPassword: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        newPassword: Yup.string()
          .min(6, "Password should have at least 6 characters")
          .max(255)
          .required("New Password required"),
        confirmNewPassword: Yup.string()
          .required("Confirm Password required")
          .oneOf([Yup.ref("newPassword")], "Passwords must match"),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting, setValues, setTouched }
      ) => {
        try {
          setSubmitting(true);
          let token = searchParams.get("token");
          if (token === null) {
            token = "";
          }
          await resetPassword(values.newPassword, token);
          setValues({
            newPassword: "",
            confirmNewPassword: "",
            submit: false,
          });
          setTouched({
            newPassword: false,
            confirmNewPassword: false,
            submit: false,
          });
          setStatus({ success: true });
          setSubmitting(false);
          navigate("/reset-password-success");
        } catch (error: any) {
          const message_default = error.message || "Something went wrong";
          const error_data = error.response.data;
          const message = error_data.detail
            ? error_data.detail
            : message_default;
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setErrors,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Stack>
              <Alert
                severity="error"
                onClose={() => {
                  setErrors({ submit: undefined });
                }}
              >
                {errors.submit}
              </Alert>
            </Stack>
          )}

          <TextField
            id="newPassword"
            name="newPassword"
            label="New password"
            type={resetValues.showNewPassword ? "text" : "password"}
            value={values.newPassword}
            error={Boolean(touched.newPassword && errors.newPassword)}
            onBlur={handleBlur}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    color="inherit"
                  >
                    {resetValues.showNewPassword ? (
                      <VisibilityOffTwoToneIcon style={{ color: "#f7d000" }} />
                    ) : (
                      <VisibilityTwoToneIcon style={{ color: "#f7d000" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {Boolean(touched.newPassword && errors.newPassword) && (
            <FormHelperText sx={{ textAlign: "center" }}>
              {errors.newPassword}
            </FormHelperText>
          )}
          <Box display="flex" justifyContent="center" marginTop={"20px"}>
            <TextField
              id="confirmNewPassword"
              name="confirmNewPassword"
              label="Confirm password"
              variant="outlined"
              type={resetValues.showConfirmNewPassword ? "text" : "password"}
              value={values.confirmNewPassword}
              error={Boolean(
                touched.confirmNewPassword && errors.confirmNewPassword
              )}
              onBlur={handleBlur}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {resetValues.showConfirmNewPassword ? (
                        <VisibilityOffTwoToneIcon
                          style={{ color: "#f7d000" }}
                        />
                      ) : (
                        <VisibilityTwoToneIcon style={{ color: "#f7d000" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {Boolean(touched.confirmNewPassword && errors.confirmNewPassword) && (
            <FormHelperText sx={{ textAlign: "center" }}>
              {errors.confirmNewPassword}
            </FormHelperText>
          )}
          <Box display="flex" justifyContent="center" marginTop={"20px"}>
            <YellowButton
              type="submit"
              disabled={isSubmitting}
              variant="contained"
            >
              Reset Password
            </YellowButton>
          </Box>
        </form>
      )}
    </Formik>
  );
}

export default ResetPasswordForm;
