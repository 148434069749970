import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import Home from "./components/Home";
import ResetPassword from "./components/ResetPassword";
import ResetPasswordSuccess from "./components/ResetPasswordSuccess";
import ValidateEmail from "./components/ValidateEmail";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="dropbox-logo.png" alt="log" />
        <h1>Dropbox</h1>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route
              path="/reset-password-success"
              element={<ResetPasswordSuccess />}
            />
            <Route path="/verify-email" element={<ValidateEmail />} />
          </Routes>
        </ThemeProvider>
      </header>
    </div>
  );
}

export default App;
