



const getErrorMessage = (error: any) => {
    const message_default =
      error.message || "Something went wrong, try again later";
    const error_data = error.response.data;
    const message = error_data.detail ? error_data.detail : message_default;
    return message;
  };


export default getErrorMessage;