import * as React from "react";
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';

function ResetPasswordSuccess() {
    return (
      <>
        <main>
          <h2>
            <CheckCircleTwoToneIcon color="success" fontSize="large" />
            Your new password is set!
          </h2>
          <h5>
            Go to dispatch your packages!
          </h5>
        </main>
      </>
    );
  }
  

export default ResetPasswordSuccess;