import React from "react";
import { useSearchParams } from "react-router-dom";

import { Box } from "@mui/material";
import ErrorOutlineTwoToneIcon from "@mui/icons-material/ErrorOutlineTwoTone";

import axios from "../utils/axios";
import getErrorMessage from "../utils/errorMessage";
import ResetPasswordForm from "./forms/ResetPasswordForm";

function ResetPassword() {
  const [searchParams] = useSearchParams();

  const [verifyValues, setVerifyValues] = React.useState({
    isTokenValid: false,
    errorMessage: "",
  });

  React.useEffect(() => {
    let token = searchParams.get("token");
    if (token === null) {
      token = "";
    }
    const isTokenValid = (token: string) => {
      axios
        .post("/users/is_token_valid", {
          token,
          type: "reset_password",
        })
        .then((res) => {
          setVerifyValues({
            ...verifyValues,
            isTokenValid: true,
          });
        })
        .catch((err) => {
          let errorMessage = getErrorMessage(err).toLowerCase();
          if (errorMessage.includes("invalid")) {
            errorMessage =
              "This link is invalid or was already used, please request a new one";
          } else if (errorMessage.includes("expired")) {
            errorMessage = "This link is expired, please request a new one";
          }
          setVerifyValues({
            ...verifyValues,
            isTokenValid: false,
            errorMessage: errorMessage,
          });
        });
    };
    isTokenValid(token);
  }, []);

  return (
    <main>
      <Box
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
      >
        {verifyValues.isTokenValid && (
          <div>
            <h2>Reset your password</h2>
            <ResetPasswordForm />
          </div>
        )}
        {verifyValues.errorMessage && (
          <div>
            <h3>
              <ErrorOutlineTwoToneIcon color="error" fontSize="large" />
              {verifyValues.errorMessage}
            </h3>
          </div>
        )}
      </Box>
    </main>
  );
}

export default ResetPassword;
