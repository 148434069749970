import * as React from "react";


function Home() {
    return (
      <>
        <main>
          <h2>Welcome to the homepage!</h2>
        </main>
      </>
    );
  }
  

export default Home;