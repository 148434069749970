import { Button, ButtonProps } from "@mui/material";
import React from "react";

interface Props extends ButtonProps {
  disabled: boolean;
}

const YellowButton: React.FC<Props> = ({
  disabled = false,
  children,
  ...props
}) => {
  return (
    <Button
      {...props}
      color="primary"
      disabled={disabled}
      style={{
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "19px",
        padding: "15px 45px",
      }}
    >
      {children}
    </Button>
  );
};

export default YellowButton;